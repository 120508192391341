import FormSummary from "./QuestionnaireFormPageSummary";

const ConfirmationModal = ({
  addressInformationForm,
  hobbiesAndSocialMediaInformationForm,
  guardianInformationOneForm,
  guardianInformationTwoForm,
  athleticInformationForm,
  academicInformationForm,
  schoolInformationForm,
  schoolCoachInformationForm,
  guidanceCounselorInformationForm,
}) => {
  return (
    <>
      <FormSummary
        heading={"Address Information"}
        formFields={addressInformationForm}
      />
      <FormSummary
        heading={"Hobbies and Social Media Information"}
        formFields={hobbiesAndSocialMediaInformationForm}
      />
      <FormSummary
        heading={"Guardian Information #1"}
        formFields={guardianInformationOneForm}
      />
      <FormSummary
        heading={"Guardian Information #2"}
        formFields={guardianInformationTwoForm}
      />
      <FormSummary
        heading={"Athletic Information"}
        formFields={athleticInformationForm}
      />
      <FormSummary
        heading={"Academic Information"}
        formFields={academicInformationForm}
      />
      <FormSummary
        heading={"School Information"}
        formFields={schoolInformationForm}
      />
      <FormSummary
        heading={"Guidance Counselor Information"}
        formFields={guidanceCounselorInformationForm}
      />
      <FormSummary
        heading={"School Coach Information"}
        formFields={schoolCoachInformationForm}
      />
    </>
  );
};

export default ConfirmationModal;
