import { Col, Descriptions, Divider, Row } from "antd";
import moment from "moment";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";

const isBoolean = val => "boolean" === typeof val;

// if the field isn't listed here,
// then it's being automatically converted from camelCase to Title Case in parseLabel
const formLabels = {
  // addressInformation
  cityOfBirth: "City Of Birth",
  liveWith: "I live with",
  parentsStatus: "My Parents Are",
  // hobbiesAndSocialMediaInformation
  otherHobbies: "Hobbies outside of football",
  otherSports: "List sports you play other than football",
  otherGuardians:
    "Outside of your parents/guardian, who will be helping you make your decision?",
  favoriteNflTeam: "Favorite NFL Team",
  youtube: "YouTube page",
  // guardian information
  isPrimaryContact: "Primary Contact",
  mobilePhoneNumber: "Mobile Phone",
  // athletic info
  hundredMeterTime: "100 Meter Time",
  fortyYardTime: "40 Yard Time",
  heightFeet: "Height (feet)",
  heightInches: "Height (inches)",
  weight: "Weight (lbs)",
  combinesAndCampsAttended: "Combines/Camps Attended",
  // academic information
  sat: "SAT",
  gpa: "GPA",
  act: "ACT",
  previousHighSchools: "Previous High Schools Attended",
  gradYear: "Graduating Class",
  // school information
  schoolAddressOne: "School Address 1",
  schoolAddressTwo: "School Address 2",
};

const FormPageHeading = ({ label = "" }) => {
  return (
    <Row style={{ paddingBottom: 8 }}>
      <Col span={24}>
        <div style={{ fontWeight: 500, fontSize: 14 }}>{label}</div>
      </Col>
    </Row>
  );
};

const QuestionnaireFormPageSummary = ({
  heading = "",
  formFields,
  withDivider = true,
  styleOverrides = null,
}) => {
  const mapFormFields = formFields => {
    const stringifiedObjectName = Object.keys({ formFields })[0];

    const parseFormFieldValue = value => {
      if (moment.isMoment(value) || moment(value).isValid()) {
        return moment(value).format("YYYY");
      } else if (isBoolean(value)) {
        return value ? "Yes" : "No";
      } else if (!value) {
        return "N/A";
      } else {
        return value;
      }
    };

    const parseLabel = formFieldKey => {
      // use an available mapping from the object at the top of the file
      // OR just convert the key name from camel case to title case
      return formLabels[formFieldKey] ?? camelCaseToTitleCase(formFieldKey);
    };
    return Object.keys(formFields).map(formFieldKey => (
      <Descriptions.Item
        label={parseLabel(formFieldKey)}
        key={`confirmation-modal-${stringifiedObjectName}-${formFieldKey}`}>
        {parseFormFieldValue(formFields[formFieldKey])}
      </Descriptions.Item>
    ));
  };
  return (
    <>
      <FormPageHeading label={heading} />
      <Descriptions bordered layout="vertical" style={styleOverrides}>
        {mapFormFields(formFields)}
      </Descriptions>
      {withDivider && <Divider />}
    </>
  );
};

export default QuestionnaireFormPageSummary;
