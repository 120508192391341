import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Layout, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlayerReferrals,
  selectReferrals,
  selectReferralsLoading,
} from "../../redux/referral_slice";
import { Link } from "@reach/router";
import PlayerTable from "../../shared-components/PlayerTable";
import moment from "moment";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import { getMyPlayerIds } from "../../redux/actions/playerProfileActions";
import {
  selectMyPlayerIds,
  selectPlayerProfileLoading,
} from "../../redux/selectors/playerProfileSelectors";
import { CheckOutlined, ShareAltOutlined } from "@ant-design/icons";
import useCopyToClipboard from "../../utils/useCopyToClipboard";
import QuestionnaireModal from "../playerComponents/QuestionnaireModal/QuestionnaireModal";
import {
  getPlayerQuestionnaireResponse,
  selectPlayerQuestionnaireDataLoading,
  selectPlayerQuestionnaireResponseDataByPlayerId,
} from "../../redux/playerQuestionnaireResponse_slice";

const PlayerReferrals = props => {
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const myPlayerIds = useSelector(selectMyPlayerIds);
  const playerProfileLoading = useSelector(selectPlayerProfileLoading);
  const playerId = parseQueryString(props.location.search, "playerId").playerId
    ? parseQueryString(props.location.search, "playerId").playerId
    : myPlayerIds[0];
  const playerData = useSelector(selectPlayerstatsByPlayerId(playerId));
  const playerQuestionnaireResponseLoading = useSelector(
    selectPlayerQuestionnaireDataLoading,
  );

  const referralData = useSelector(selectReferrals);
  const loading = useSelector(selectReferralsLoading);

  const referralDataMapped = referralData?.map(referral => {
    return {
      ...referral,
      referralUserName: referral?.recruiterName || referral?.coachName || "N/A",
      referralSchoolName:
        referral?.recruiterSchoolName || referral?.coachSchoolName || "N/A",
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!playerProfileLoading && !playerId && !playerData) {
      dispatch(getMyPlayerIds());
    }
  }, []);

  useEffect(() => {
    // no data check here to make the last date column update whenever the page loads in
    if (!loading && playerId) {
      console.log("playerId", playerId);
      dispatch(getPlayerReferrals(playerId));
    }
  }, [playerId]);

  useEffect(() => {
    if (!playerQuestionnaireResponseLoading && playerId) {
      dispatch(getPlayerQuestionnaireResponse(playerId));
    }
  }, [playerId]);

  const columns = [
    { title: "Referred Email", dataIndex: "referredEmail" },
    {
      title: "Viewed by",
      dataIndex: "referralUserName",
    },
    {
      title: "School",
      dataIndex: "referralSchoolName",
    },
    { title: "View Count", dataIndex: "viewCount" },
    {
      title: "Last viewed",
      dataIndex: "lastView",
      render(text, render) {
        return <div>{moment(new Date(text)).format("M/D/YYYY h:mm a")}</div>;
      },
    },
  ];
  return (
    <Layout style={{ minWidth: "600px" }}>
      <Layout.Content style={{ marginLeft: 0, marginRight: 30, marginTop: 15 }}>
        <h1>Referrals</h1>
      </Layout.Content>
      <Layout.Content style={{ marginLeft: 0, marginTop: 15 }}>
        <Col xs={24} lg={14}>
          <Row>
            <Button
              loading={playerQuestionnaireResponseLoading}
              onClick={() => {
                setShowQuestionnaireModal(true);
              }}>
              Recruiting Questionnaire
            </Button>
            <QuestionnaireModal
              open={showQuestionnaireModal}
              setOpen={setShowQuestionnaireModal}
              playerId={playerId}
            />
            <Button
              onClick={() => {
                copyToClipboard(
                  `${window.location.origin}/referrals/players/${playerId}`,
                );
              }}
              style={{ marginLeft: 16 }}
              icon={<ShareAltOutlined />}
              type="primary">
              Share my Profile
            </Button>
            {isCopied && (
              <Alert
                showIcon={true}
                icon={<CheckOutlined />}
                style={{ marginLeft: 16, padding: "4px 15px" }}
                type="success"
                message="Link copied to clickboard!"
              />
            )}
          </Row>
        </Col>
        <Col xs={24} lg={18}>
          <PlayerTable
            playerData={referralDataMapped}
            columns={columns}
            loading={loading}
            navBase={"/referrals"}
          />
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default PlayerReferrals;
