import { useState } from "react";
import QuestionnaireFormPageSummary from "../QuestionnaireModal/QuestionnaireFormPageSummary";
import { Button, Col, Row, Form, Modal, Steps, Divider } from "antd";

const QuestionnaireResponsesModal = ({
  open,
  setOpen,
  playerQuestionnaireResponse,
}) => {
  const [page, setPage] = useState(0);

  const handleModalClose = () => {
    setOpen(false);
    setPage(0);
  };

  const handleBackPress = () => {
    if (page === 0) {
      handleModalClose();
    } else {
      setPage(prev => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (page === pages.length - 1) {
      handleModalClose();
    } else {
      setPage(prev => prev + 1);
    }
  };

  const pages = [
    {
      title: "Address Information",
      component: (
        <QuestionnaireFormPageSummary
          heading="Address Information"
          formFields={
            playerQuestionnaireResponse?.responseJson?.addressInformation
          }
          withDivider={false}
          styleOverrides={{ marginBottom: 8 }}
        />
      ),
    },
    {
      title: "Hobbies And Social Media Information",
      component: (
        <QuestionnaireFormPageSummary
          heading={"Hobbies And Social Media Information"}
          formFields={
            playerQuestionnaireResponse?.responseJson
              ?.hobbiesAndSocialMediaInformation
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "Guardian Information #1",
      component: (
        <QuestionnaireFormPageSummary
          heading={"Guardian Information #1"}
          formFields={
            playerQuestionnaireResponse?.responseJson?.guardianInformationOne
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "Guardian Information #2",
      component: (
        <QuestionnaireFormPageSummary
          heading={"Guardian Information #2"}
          formFields={
            playerQuestionnaireResponse?.responseJson?.guardianInformationTwo
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "Athletic Information",
      component: (
        <QuestionnaireFormPageSummary
          heading={"Athletic Information"}
          formFields={
            playerQuestionnaireResponse?.responseJson?.athleticInformation
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "Academic Information",
      component: (
        <QuestionnaireFormPageSummary
          heading={"Academic Information"}
          formFields={
            playerQuestionnaireResponse?.responseJson?.academicInformation
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "Guidance Counselor Information",
      component: (
        <QuestionnaireFormPageSummary
          heading={"Guidance Counselor Information"}
          formFields={
            playerQuestionnaireResponse?.responseJson
              ?.guidanceCounselorInformation
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "School Information",
      component: (
        <QuestionnaireFormPageSummary
          heading={"School Information"}
          formFields={
            playerQuestionnaireResponse?.responseJson?.schoolInformation
          }
          withDivider={false}
        />
      ),
    },
    {
      title: "School Coach Information",
      component: (
        <QuestionnaireFormPageSummary
          heading={"School Coach Information"}
          formFields={
            playerQuestionnaireResponse?.responseJson?.schoolCoachInformation
          }
          withDivider={false}
        />
      ),
    },
  ];

  return (
    <>
      <Modal
        open={open}
        width={pages[page]?.modalWidth ?? 900}
        maskClosable={false}
        title={"Questionnaire Responses"}
        onCancel={handleModalClose}
        footer={[
          ...(page !== 0
            ? [
                <Button onClick={handleBackPress}>
                  {page === 0 ? "Close" : "Back"}
                </Button>,
              ]
            : []),
          <Button type="primary" onClick={handleNextPage}>
            {page === pages.length - 1 ? "Close" : "Next"}
          </Button>,
        ]}>
        <Row>
          <Col xs={0} md={6}>
            <Steps
              current={page}
              onChange={value => {
                setPage(value);
              }}
              size="small"
              direction="vertical"
              items={pages.map((currentPage, index) => ({
                title: currentPage.title,
                // this just displays numbers for all steps that have been passed instead of showing a checkmark icon
                status: page === index ? "process" : "wait",
              }))}
            />
          </Col>
          <Col xs={0} md={1}>
            <Divider
              style={{
                height: "100%",
              }}
              type="vertical"
            />
          </Col>
          <Col xs={24} md={17}>
            {pages[page]?.component}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default QuestionnaireResponsesModal;
