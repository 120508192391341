import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
// import * as queryString from "query-string";

const slice = createSlice({
  name: "playerQuestionnaireResponseData",
  initialState: {
    loading: false,
    updating: false,
    dataByPlayerId: {},
    error: null,
  },
  reducers: {
    playerQuestionnaireResponseDataLoading: state => {
      state.loading = true;
      state.error = null;
    },
    getPlayerQuestionnaireResponseSuccess: (state, action) => {
      state.loading = false;

      if (state.dataByPlayerId[action.payload.playerId]) {
        state.dataByPlayerId[action.payload.playerId] = {
          ...state.dataByPlayerId[action.payload.playerId],
          ...action.payload.data,
        };
      } else {
        state.dataByPlayerId = {
          ...state.dataByPlayerId,
          [action.payload.playerId]: {
            ...action.payload.data,
          },
        };
      }

      state.error = null;
    },
    upsertPlayerQuestionnaireResponseSuccess: (state, action) => {
      state.loading = false;

      if (state.dataByPlayerId[action.payload.playerId]) {
        state.dataByPlayerId[action.payload.playerId] = {
          ...state.dataByPlayerId[action.payload.playerId],
          ...action.payload.data,
        };
      } else {
        state.dataByPlayerId = {
          ...state.dataByPlayerId,
          [action.payload.playerId]: {
            ...action.payload.data,
          },
        };
      }

      state.error = null;
    },
    getPlayerQuestionnaireResponseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  playerQuestionnaireResponseDataLoading,
  getPlayerQuestionnaireResponseSuccess,
  upsertPlayerQuestionnaireResponseSuccess,
  getPlayerQuestionnaireResponseFailure,
} = slice.actions;

export const getPlayerQuestionnaireResponse = playerId => async dispatch => {
  try {
    dispatch(playerQuestionnaireResponseDataLoading());
    console.log("getting player questionnaire response");
    const response = await API.get(
      `playerAPI`,
      `player-questionnaire-response/${playerId}`,
    );
    console.log("got player questionnaire response", response);

    if (response.error) {
      dispatch(getPlayerQuestionnaireResponseFailure(response.error));
    } else {
      dispatch(
        getPlayerQuestionnaireResponseSuccess({ playerId, data: response }),
      );
    }
  } catch (e) {
    dispatch(getPlayerQuestionnaireResponseFailure(e));
  }
};

export const upsertPlayerQuestionnaireResponse =
  (playerId, data) => async dispatch => {
    try {
      dispatch(playerQuestionnaireResponseDataLoading());
      console.log("getting player questionnaire response");
      const response = await API.put(
        `playerAPI`,
        `player-questionnaire-response/${playerId}`,
        {
          body: data,
        },
      );
      console.log("got player questionnaire response", response);

      if (response.error) {
        dispatch(getPlayerQuestionnaireResponseFailure(response.error));
      } else {
        dispatch(
          upsertPlayerQuestionnaireResponseSuccess({
            playerId,
            data: response,
          }),
        );
      }
    } catch (e) {
      dispatch(getPlayerQuestionnaireResponseFailure(e));
    }
  };

export const selectPlayerQuestionnaireDataLoading = store =>
  store.playerQuestionnaireResponseData.loading;

export const selectPlayerQuestionnaireResponseDataByPlayerId =
  playerId => store => {
    try {
      return {
        ...store.playerQuestionnaireResponseData.dataByPlayerId[playerId],
        // run a JSON parse to make it easier to work with later
        responseJson:
          JSON.parse(store?.playerQuestionnaireResponseData?.dataByPlayerId[playerId]?.responseJson),
      };
    } catch (e) {
      return null;
    }
  };
