import { Button, Form, Modal } from "antd";
import { useEffect, useState } from "react";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import GuardianInformation from "./GuardianInformation";
import AddressInformation from "./PersonalInformation/AddressInformation";
import HobbiesAndSocialMediaInformation from "./PersonalInformation/HobbiesAndSocialMediaInformation";
import AthleticInformation from "./AthleticInformation";
import AcademicInformation from "./AcademicInformation";

import SchoolCoachInformation from "./SchoolCoachInformation";
import SchoolInformation from "./SchoolInformation";
import GuidanceCounselorInformation from "./GuidanceCounselorInformation";
import ConfirmationModal from "./ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayerQuestionnaireResponseDataByPlayerId,
  upsertPlayerQuestionnaireResponse,
} from "../../../redux/playerQuestionnaireResponse_slice";
import moment from "moment";

const QuestionnaireModal = ({ open, setOpen, playerId }) => {
  const playerQuestionnaireResponse = useSelector(
    selectPlayerQuestionnaireResponseDataByPlayerId(playerId),
  );

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [addressInformationForm] = Form.useForm();
  const [hobbiesAndSocialMediaInformationForm] = Form.useForm();
  const [guardianInformationOneForm] = Form.useForm();
  const [guardianInformationTwoForm] = Form.useForm();
  const [athleticInformationForm] = Form.useForm();
  const [academicInformationForm] = Form.useForm();
  const [schoolInformationForm] = Form.useForm();
  const [guidanceCounselorInformationForm] = Form.useForm();
  const [schoolCoachInformationForm] = Form.useForm();

  const initializeForm = () => {
    addressInformationForm.setFieldsValue({
      ...addressInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.addressInformation,
    });
    hobbiesAndSocialMediaInformationForm.setFieldsValue({
      ...hobbiesAndSocialMediaInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson
        ?.hobbiesAndSocialMediaInformation,
    });
    guardianInformationOneForm.setFieldsValue({
      ...guardianInformationOneForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.guardianInformationOne,
    });
    guardianInformationTwoForm.setFieldsValue({
      ...guardianInformationTwoForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.guardianInformationTwo,
    });
    athleticInformationForm.setFieldsValue({
      ...athleticInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.athleticInformation,
    });
    academicInformationForm.setFieldsValue({
      ...academicInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.academicInformation,
      // need to parse gradYear with moment before setting state
      // or it will cause the form page for it to error out
      gradYear: playerQuestionnaireResponse?.responseJson?.academicInformation
        ?.gradYear
        ? moment(
            playerQuestionnaireResponse?.responseJson?.academicInformation
              ?.gradYear,
          )
        : undefined,
    });
    schoolInformationForm.setFieldsValue({
      ...schoolInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.schoolInformation,
    });
    guidanceCounselorInformationForm.setFieldsValue({
      ...guidanceCounselorInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson
        ?.guidanceCounselorInformation,
    });
    schoolCoachInformationForm.setFieldsValue({
      ...schoolCoachInformationForm?.getFieldValue([]),
      ...playerQuestionnaireResponse?.responseJson?.schoolCoachInformation,
    });
  };

  useEffect(() => {
    if (open) {
      initializeForm();
    }
  }, [open]);

  const resetFormInstances = () => {
    const formInstances = [
      addressInformationForm,
      academicInformationForm,
      hobbiesAndSocialMediaInformationForm,
      guardianInformationOneForm,
      guardianInformationTwoForm,
      athleticInformationForm,
      academicInformationForm,
      schoolInformationForm,
      guidanceCounselorInformationForm,
      schoolCoachInformationForm,
    ];

    formInstances.forEach(instance => {
      instance.resetFields();
    });
  };

  const resetFormState = () => {
    setPage(0);
    resetFormInstances();
    setOpen(false);
  };

  const handleBack = () => {
    if (page === 0) {
      resetFormState();
    } else {
      setPage(prev => prev - 1);
    }
  };

  const handleFinalSubmit = async () => {
    console.log("performing final submit");
    setSubmitLoading(true);
    try {
      const finalData = {
        addressInformation: addressInformationForm?.getFieldValue([]),
        academicInformation: academicInformationForm?.getFieldValue([]),
        hobbiesAndSocialMediaInformation:
          hobbiesAndSocialMediaInformationForm?.getFieldValue([]),
        guardianInformationOne: guardianInformationOneForm?.getFieldValue([]),
        guardianInformationTwo: guardianInformationTwoForm?.getFieldValue([]),
        athleticInformation: athleticInformationForm?.getFieldValue([]),
        academicInformation: {
          ...academicInformationForm?.getFieldValue([]),
          gradYear: moment(
            academicInformationForm?.getFieldValue("gradYear"),
          ).toDate(),
        },
        schoolInformation: schoolInformationForm?.getFieldValue([]),
        guidanceCounselorInformation:
          guidanceCounselorInformationForm?.getFieldValue([]),
        schoolCoachInformation: schoolCoachInformationForm?.getFieldValue([]),
      };

      await dispatch(upsertPlayerQuestionnaireResponse(playerId, finalData));

      resetFormState();
    } catch (error) {
      console.log("error submitting modal", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleNextPage = async formInstance => {
    try {
      const result = await formInstance.validateFields();
      if (page === pages.length - 1) {
        handleFinalSubmit();
      } else {
        setPage(prev => prev + 1);
      }
    } catch (error) {
      console.log("error submitting form", error);
    }
  };

  const pages = [
    {
      title: "Questionnaire - Personal Information",
      component: <AddressInformation formInstance={addressInformationForm} />,
      footer: [
        <Button
          onClick={() => {
            handleNextPage(addressInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - Personal Information",
      component: (
        <HobbiesAndSocialMediaInformation
          formInstance={hobbiesAndSocialMediaInformationForm}
        />
      ),
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(hobbiesAndSocialMediaInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - Parent/Guardian Information #1",
      component: (
        <GuardianInformation
          formName={"guardianInformationOne"}
          formInstance={guardianInformationOneForm}
        />
      ),
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(guardianInformationOneForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - Parent/Guardian Information #2",
      component: (
        <GuardianInformation
          required={false}
          formName={"guardianInformationTwo"}
          formInstance={guardianInformationTwoForm}
        />
      ),
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(guardianInformationTwoForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - Athletic Information",
      component: <AthleticInformation formInstance={athleticInformationForm} />,
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(athleticInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - Academic Information",
      component: <AcademicInformation formInstance={academicInformationForm} />,
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(academicInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - School Information",
      component: <SchoolInformation formInstance={schoolInformationForm} />,
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(schoolInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - School Guidance Counselor Information",
      component: (
        <GuidanceCounselorInformation
          formInstance={guidanceCounselorInformationForm}
        />
      ),
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          onClick={() => {
            handleNextPage(guidanceCounselorInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - School Coach Information",
      component: (
        <SchoolCoachInformation formInstance={schoolCoachInformationForm} />
      ),
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          loading={submitLoading}
          onClick={() => {
            handleNextPage(schoolCoachInformationForm);
          }}>
          Next
        </Button>,
      ],
    },
    {
      title: "Questionnaire - Confirm Details",
      modalWidth: 700,
      component: (
        <ConfirmationModal
          addressInformationForm={addressInformationForm?.getFieldValue([])}
          hobbiesAndSocialMediaInformationForm={hobbiesAndSocialMediaInformationForm?.getFieldValue(
            [],
          )}
          guardianInformationOneForm={guardianInformationOneForm?.getFieldValue(
            [],
          )}
          guardianInformationTwoForm={guardianInformationTwoForm?.getFieldValue(
            [],
          )}
          athleticInformationForm={athleticInformationForm?.getFieldValue([])}
          academicInformationForm={academicInformationForm?.getFieldValue([])}
          schoolInformationForm={schoolInformationForm?.getFieldValue([])}
          guidanceCounselorInformationForm={guidanceCounselorInformationForm?.getFieldValue(
            [],
          )}
          schoolCoachInformationForm={schoolCoachInformationForm?.getFieldValue(
            [],
          )}
        />
      ),
      footer: [
        <Button
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>,
        <Button
          loading={submitLoading}
          type="primary"
          onClick={() => {
            handleNextPage(schoolCoachInformationForm);
          }}>
          Submit
        </Button>,
      ],
    },
  ];

  return (
    <Modal
      open={open}
      width={pages[page]?.modalWidth ?? 600}
      maskClosable={false}
      title={pages[page]?.title}
      onCancel={resetFormState}
      footer={pages[page]?.footer ?? []}>
      {pages[page]?.component}
    </Modal>
  );
};

export default QuestionnaireModal;
